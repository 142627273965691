var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isSetupWizard
        ? _c("GeneralSubHeader", {
            scopedSlots: _vm._u(
              [
                {
                  key: "title",
                  fn: function () {
                    return [_vm._v(" Google Course Manager ")]
                  },
                  proxy: true,
                },
                {
                  key: "left-buttons",
                  fn: function () {
                    return undefined
                  },
                  proxy: true,
                },
                {
                  key: "buttons",
                  fn: function () {
                    return [
                      _vm.accountLinked
                        ? _c("div", { staticClass: "kt-subheader__wrapper" }, [
                            !_vm.isBulkReady
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-primary",
                                    attrs: { disabled: _vm.saving },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.showBulkModal.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "socicon-google mt-1 mr-1",
                                    }),
                                    _vm._v(
                                      " Apply " +
                                        _vm._s(
                                          _vm.selectedGoogleCourses.length
                                        ) +
                                        " Bulk Actions "
                                    ),
                                  ]
                                )
                              : _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-label-primary",
                                    attrs: { disabled: true },
                                  },
                                  [_vm._v(" Apply Bulk Action ")]
                                ),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary ml-3",
                                class: {
                                  "kt-spinner kt-spinner--sm kt-spinner--light":
                                    _vm.refreshing,
                                },
                                attrs: {
                                  disabled: _vm.saving || _vm.refreshing,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.refreshCourses()
                                  },
                                },
                              },
                              [
                                !_vm.refreshing
                                  ? _c("i", { staticClass: "la la-refresh" })
                                  : _vm._e(),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.refreshing
                                        ? "Please wait..."
                                        : "Refresh Courses"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3539899622
            ),
          })
        : _vm._e(),
      _c("b-modal", {
        ref: "bulk-apply-modal",
        attrs: { size: "xl", centered: "" },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function ({ close }) {
              return [
                _c("h5", { staticClass: "modal-title" }, [
                  _c("i", {
                    staticClass: "mr-2 kt-font-primary",
                    class: _vm.activeTab.icon,
                  }),
                  _vm._v(" " + _vm._s(_vm.activeTab.title) + " "),
                ]),
                _c("button", {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    "aria-label": "Close",
                  },
                  on: {
                    click: function ($event) {
                      return close()
                    },
                  },
                }),
              ]
            },
          },
          {
            key: "default",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "kt-portlet__body pt-3 pb-4 pl-4 pr-4" },
                  [
                    _c(
                      "ul",
                      {
                        staticClass: "nav nav-pills",
                        attrs: { role: "tablist" },
                      },
                      _vm._l(_vm.bulk.options, function (option) {
                        return _c(
                          "li",
                          {
                            key: `key_${option.name}`,
                            staticClass: "nav-link",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.selectBulkOption(option.name)
                              },
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link pt-3 pb-3 pr-4",
                                class: { active: option.active },
                                attrs: { href: "#" },
                              },
                              [
                                _c("i", {
                                  staticClass: "mr-2",
                                  class: option.icon,
                                }),
                                _vm._v(" " + _vm._s(option.name) + " "),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm.selectedGoogleCourses.length > 0 && _vm.user.googleEmail
                      ? _c(
                          "div",
                          { staticClass: "tab-content p-4" },
                          [
                            _vm.activeTab.name == "Change Owner"
                              ? [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "kt-section kt-section--first",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "kt-section__body" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group row validated",
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "col-lg-3 col-form-label",
                                                },
                                                [_vm._v("New Owner:")]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-lg-6" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.bulk.newOwner,
                                                        expression:
                                                          "bulk.newOwner",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    class:
                                                      !_vm.bulk.newOwner ||
                                                      _vm.isValidOwnerDomain
                                                        ? ""
                                                        : "is-invalid",
                                                    attrs: {
                                                      placeholder: `e.g. barack.obama@${_vm.googleDomain}`,
                                                      type: "email",
                                                    },
                                                    domProps: {
                                                      value: _vm.bulk.newOwner,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.bulk,
                                                          "newOwner",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm.bulk.newOwner == ""
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "form-text text-muted",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Please enter the email address of the new owner "
                                                          ),
                                                        ]
                                                      )
                                                    : !_vm.isValidOwnerDomain
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "form-text text-muted invalid-warn-feedback",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Please enter a valid email address ending in "
                                                          ),
                                                          _c("code", [
                                                            _vm._v(
                                                              "@" +
                                                                _vm._s(
                                                                  _vm.googleDomain
                                                                )
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                            _vm.activeTab.name == "Add Teacher"
                              ? [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "kt-section kt-section--first",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "kt-section__body" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-group row" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "col-lg-3 col-form-label",
                                                },
                                                [_vm._v("Email Address:")]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-lg-6" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.bulk.teacherToAdd,
                                                        expression:
                                                          "bulk.teacherToAdd",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    class:
                                                      !_vm.bulk.teacherToAdd ||
                                                      _vm.isValidTeacherToAdd
                                                        ? ""
                                                        : "is-invalid",
                                                    attrs: {
                                                      placeholder: `e.g. barack.obama@${_vm.googleDomain}`,
                                                      type: "email",
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.bulk.teacherToAdd,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.bulk,
                                                          "teacherToAdd",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm.bulk.teacherToAdd == ""
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "form-text text-muted",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Please enter the email address of the the teacher to add "
                                                          ),
                                                        ]
                                                      )
                                                    : !_vm.isValidTeacherToAdd
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "form-text text-muted invalid-warn-feedback",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Please enter a valid email address ending in "
                                                          ),
                                                          _c("code", [
                                                            _vm._v(
                                                              "@" +
                                                                _vm._s(
                                                                  _vm.googleDomain
                                                                )
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                            _vm.activeTab.name == "Remove Teacher"
                              ? [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "kt-section kt-section--first",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "kt-section__body" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-group row" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "col-lg-3 col-form-label",
                                                },
                                                [_vm._v("Email Address:")]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-lg-6" },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.bulk
                                                            .teacherToRemove,
                                                        expression:
                                                          "bulk.teacherToRemove",
                                                      },
                                                    ],
                                                    staticClass: "form-control",
                                                    class:
                                                      !_vm.bulk
                                                        .teacherToRemove ||
                                                      _vm.isValidTeacherToRemove
                                                        ? ""
                                                        : "is-invalid",
                                                    attrs: {
                                                      placeholder: `e.g. barack.obama@${_vm.googleDomain}`,
                                                      type: "email",
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.bulk
                                                          .teacherToRemove,
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.bulk,
                                                          "teacherToRemove",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm.bulk.teacherToRemove == ""
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "form-text text-muted",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Please enter the email address of the the teacher to remove "
                                                          ),
                                                        ]
                                                      )
                                                    : !_vm.isValidTeacherToRemove
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "form-text text-muted invalid-warn-feedback",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Please enter a valid email address ending in "
                                                          ),
                                                          _c("code", [
                                                            _vm._v(
                                                              "@" +
                                                                _vm._s(
                                                                  _vm.googleDomain
                                                                )
                                                            ),
                                                          ]),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                            _vm.activeTab.name == "Change State"
                              ? [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "kt-section kt-section--first",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "kt-section__body" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group row pl-5 pt-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-lg-4" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "kt-option kt-option--plain",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "kt-option__control",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "kt-radio kt-radio--success",
                                                            },
                                                            [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm.bulk
                                                                        .newState,
                                                                    expression:
                                                                      "bulk.newState",
                                                                  },
                                                                ],
                                                                attrs: {
                                                                  type: "radio",
                                                                  name: "googleCourseState",
                                                                  value:
                                                                    "ACTIVE",
                                                                },
                                                                domProps: {
                                                                  checked:
                                                                    _vm._q(
                                                                      _vm.bulk
                                                                        .newState,
                                                                      "ACTIVE"
                                                                    ),
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$set(
                                                                        _vm.bulk,
                                                                        "newState",
                                                                        "ACTIVE"
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                              _c("span"),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "kt-option__label",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "kt-option__head",
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "kt-option__title kt-font-success",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Activate "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "kt-option__body",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Mark the course as active. Active courses are available to teachers and students in google classroom "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-lg-4" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "kt-option kt-option--plain",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "kt-option__control",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "kt-radio kt-radio--warning",
                                                            },
                                                            [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm.bulk
                                                                        .newState,
                                                                    expression:
                                                                      "bulk.newState",
                                                                  },
                                                                ],
                                                                attrs: {
                                                                  type: "radio",
                                                                  name: "googleCourseState",
                                                                  value:
                                                                    "ARCHIVED",
                                                                },
                                                                domProps: {
                                                                  checked:
                                                                    _vm._q(
                                                                      _vm.bulk
                                                                        .newState,
                                                                      "ARCHIVED"
                                                                    ),
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$set(
                                                                        _vm.bulk,
                                                                        "newState",
                                                                        "ARCHIVED"
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                              _c("span"),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "kt-option__label",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "kt-option__head",
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "kt-option__title kt-font-warning",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Archive "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "kt-option__body",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Mark the course as archived. You cannot modify archived courses except to mark as active. "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-lg-4" },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "kt-option kt-option--plain",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "kt-option__control",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "kt-radio kt-radio--danger",
                                                            },
                                                            [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm.bulk
                                                                        .newState,
                                                                    expression:
                                                                      "bulk.newState",
                                                                  },
                                                                ],
                                                                attrs: {
                                                                  type: "radio",
                                                                  name: "googleCourseState",
                                                                  value:
                                                                    "DELETED",
                                                                },
                                                                domProps: {
                                                                  checked:
                                                                    _vm._q(
                                                                      _vm.bulk
                                                                        .newState,
                                                                      "DELETED"
                                                                    ),
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.$set(
                                                                        _vm.bulk,
                                                                        "newState",
                                                                        "DELETED"
                                                                      )
                                                                    },
                                                                },
                                                              }),
                                                              _c("span"),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "kt-option__label",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "kt-option__head",
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "kt-option__title kt-font-danger",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Delete "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "kt-option__body",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Once a course is deleted, it is permanently removed from google classroom. Grade data will remain in SyncGrades after deletion. "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm.bulk.newState == "DELETED"
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "form-group row",
                                                },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "col-lg-3 col-form-label",
                                                    },
                                                    [_vm._v("Confirm Delete:")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "col-lg-6" },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.bulk
                                                                .deleteConfirm,
                                                            expression:
                                                              "bulk.deleteConfirm",
                                                          },
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: { type: "text" },
                                                        domProps: {
                                                          value:
                                                            _vm.bulk
                                                              .deleteConfirm,
                                                        },
                                                        on: {
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              _vm.bulk,
                                                              "deleteConfirm",
                                                              $event.target
                                                                .value
                                                            )
                                                          },
                                                        },
                                                      }),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "form-text text-muted kt-font-danger",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Please enter the text "
                                                          ),
                                                          _c("code", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.bulk
                                                                  .deleteConfirmText
                                                              )
                                                            ),
                                                          ]),
                                                          _vm._v(
                                                            " to acknowledge that this delete is permanant and to proceed "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "modal-footer",
            fn: function ({ close }) {
              return [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary mr-3",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return close()
                      },
                    },
                  },
                  [_vm._v(" Cancel ")]
                ),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-primary btn-bold pull-right",
                    class: {
                      "kt-spinner kt-spinner--sm kt-spinner--light": _vm.saving,
                    },
                    attrs: { href: "#", disabled: _vm.saving },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.applyBulk()
                      },
                    },
                  },
                  [
                    !_vm.saving
                      ? _c("i", { staticClass: "fa fa-save" })
                      : _vm._e(),
                    _vm._v(
                      " " +
                        _vm._s(_vm.saving ? "Saving..." : "Apply Changes") +
                        " "
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "div",
        {
          staticClass:
            "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid pl-0 pb-5",
        },
        [
          _vm.isSetupWizard && _vm.step
            ? _c("SetupWizardStepStatus", {
                attrs: { status: _vm.step.status, message: _vm.step.message },
              })
            : _vm._e(),
          _c(
            "b-overlay",
            {
              attrs: {
                "no-center": "",
                opacity: "0.8",
                variant: "light",
                show: _vm.saving,
              },
              scopedSlots: _vm._u([
                {
                  key: "overlay",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center mt-5" },
                        [
                          !_vm.bulk.canCloseOverlay
                            ? [
                                _c("b-icon", {
                                  attrs: {
                                    icon: "stopwatch",
                                    "font-scale": "3",
                                    animation: "cylon",
                                  },
                                }),
                                _c("h6", { staticClass: "mt-4" }, [
                                  _vm._v(
                                    " These operations can take some time. Please keep this window open until completed. "
                                  ),
                                ]),
                              ]
                            : [
                                _c(
                                  "h4",
                                  {
                                    staticClass:
                                      "mt-4 ml-4 text-left kt-font-primary",
                                  },
                                  [
                                    _c("b-icon", {
                                      staticClass: "kt-font-primary mr-3",
                                      attrs: {
                                        icon: "check",
                                        "font-scale": "1.2",
                                      },
                                    }),
                                    _vm._v(" Bulk action has completed. "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-sm btn-primary pull-right mr-3",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            $event.preventDefault()
                                            return _vm.closeOverlay.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", { staticClass: "la la-close" }),
                                        _vm._v(" Close "),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                          _c("div", { staticClass: "p-4 mt-2" }, [
                            _c(
                              "div",
                              { staticClass: "progress progress--lg" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "progress-bar kt-bg-primary",
                                    style: { width: _vm.percentComplete + "%" },
                                    attrs: { role: "progressbar" },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.percentComplete) + "% "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "div",
                            { staticClass: "logs p-4 text-left" },
                            _vm._l(_vm.logs, function (item, idx) {
                              return _c("p", { key: `item_${idx}` }, [
                                item.status == "info"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "kt-badge kt-badge--info kt-badge--inline mr-3",
                                      },
                                      [_vm._v("INFO")]
                                    )
                                  : _vm._e(),
                                item.status == "success"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "kt-badge kt-badge--success kt-badge--inline mr-3",
                                      },
                                      [_vm._v("SUCCESS")]
                                    )
                                  : _vm._e(),
                                item.status == "danger"
                                  ? _c(
                                      "span",
                                      {
                                        staticClass:
                                          "kt-badge kt-badge--danger kt-badge--inline mr-3",
                                      },
                                      [_vm._v("ERROR")]
                                    )
                                  : _vm._e(),
                                _vm._v(" " + _vm._s(item.message) + " "),
                              ])
                            }),
                            0
                          ),
                        ],
                        2
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              !_vm.accountLinked ? _c("GoogleAccountWarning") : _vm._e(),
              _c("div", { staticClass: "kt-portlet" }, [
                _c("div", { staticClass: "kt-portlet__body" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "kt-form kt-form--label-right kt-margin-t-20 kt-margin-b-10",
                    },
                    [
                      _c("div", { staticClass: "row align-items-center" }, [
                        _c(
                          "div",
                          { staticClass: "col-xl-8 order-2 order-xl-1" },
                          [
                            _c(
                              "div",
                              { staticClass: "row align-items-center" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-md-4 kt-margin-b-20-tablet-and-mobile",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "kt-input-icon kt-input-icon--left",
                                      },
                                      [
                                        _c("b-form-input", {
                                          staticClass: "form-control",
                                          attrs: {
                                            debounce: "500",
                                            placeholder: "Search...",
                                            type: "text",
                                          },
                                          model: {
                                            value: _vm.filter,
                                            callback: function ($$v) {
                                              _vm.filter = $$v
                                            },
                                            expression: "filter",
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "kt-input-icon__icon kt-input-icon__icon--left",
                                          },
                                          [
                                            _c("span", [
                                              _c("i", {
                                                staticClass: "la la-search",
                                              }),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-md-4 kt-margin-b-20-tablet-and-mobile",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "kt-form__group kt-form__group--inline",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "kt-form__label" },
                                          [_c("label")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "kt-form__control dropdown bootstrap-select",
                                          },
                                          [
                                            _c(
                                              "b-dropdown",
                                              {
                                                staticClass:
                                                  "m-2 btn-light w-100",
                                                attrs: {
                                                  "no-caret": "",
                                                  text:
                                                    _vm.courseState ||
                                                    "Any Status",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-dropdown-item-button",
                                                  {
                                                    staticClass:
                                                      "dropdown-item pl-4 pt-3 pb-3 pr-3",
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        _vm.courseState = null
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" Any Status ")]
                                                ),
                                                _c(
                                                  "b-dropdown-item-button",
                                                  {
                                                    staticClass:
                                                      "dropdown-item pl-4 pt-3 pb-3 pr-3",
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        _vm.courseState =
                                                          "ACTIVE"
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" Active Courses ")]
                                                ),
                                                _c(
                                                  "b-dropdown-item-button",
                                                  {
                                                    staticClass:
                                                      "dropdown-item pl-4 pt-3 pb-3 pr-3",
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        _vm.courseState =
                                                          "ARCHIVED"
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" Archived Courses ")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-md-4 kt-margin-b-20-tablet-and-mobile",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "kt-form__group kt-form__group--inline",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "kt-form__label" },
                                          [_c("label")]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "kt-form__control dropdown bootstrap-select",
                                          },
                                          [
                                            _c(
                                              "b-dropdown",
                                              {
                                                staticClass:
                                                  "m-2 btn-light w-100",
                                                attrs: {
                                                  "no-caret": "",
                                                  text:
                                                    _vm.linkState ||
                                                    "All Courses",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-dropdown-item-button",
                                                  {
                                                    staticClass:
                                                      "dropdown-item pl-4 pt-3 pb-3 pr-3",
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        _vm.linkState = null
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" All Courses ")]
                                                ),
                                                _c(
                                                  "b-dropdown-item-button",
                                                  {
                                                    staticClass:
                                                      "dropdown-item pl-4 pt-3 pb-3 pr-3",
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        _vm.linkState = "LINKED"
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" Linked this term ")]
                                                ),
                                                _c(
                                                  "b-dropdown-item-button",
                                                  {
                                                    staticClass:
                                                      "dropdown-item pl-4 pt-3 pb-3 pr-3",
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        $event.preventDefault()
                                                        _vm.linkState =
                                                          "NOT LINKED"
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Not Linked this term "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "kt-portlet__body kt-portlet__body--fit" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded",
                        },
                        [
                          _c("b-table", {
                            ref: "dataSet",
                            staticClass: "mt-5",
                            attrs: {
                              id: "data-set",
                              items: _vm.courses,
                              fields: _vm.fields,
                              "current-page": _vm.currentPage,
                              "per-page": _vm.perPage,
                              filter: _vm.filter,
                              stacked: "sm",
                              selectable: "",
                              fixed: "",
                              "selected-variant": "",
                              "show-empty": "",
                              hover: "",
                              responsive: "",
                              small: "",
                              "select-mode": "multi",
                            },
                            on: {
                              "row-selected": _vm.onRowSelected,
                              filtered: _vm.onFiltered,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "table-colgroup",
                                fn: function (scope) {
                                  return _vm._l(scope.fields, function (field) {
                                    return _c("col", {
                                      key: field.key,
                                      style: field.style || "",
                                    })
                                  })
                                },
                              },
                              {
                                key: "cell(googleCourseName)",
                                fn: function (row) {
                                  return [
                                    _c("div", { staticClass: "course-title" }, [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "kt-link",
                                          attrs: {
                                            target: "_blank",
                                            title: `Open in Google: ${row.item.googleCourseName}`,
                                            href: row.item.alternateLink,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                row.item.googleCourseName
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    row.item.googleCourseSection
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "course-title text-muted",
                                            attrs: {
                                              title:
                                                row.item.googleCourseSection,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  row.item.googleCourseSection
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                              {
                                key: "cell(selected)",
                                fn: function (row) {
                                  return [
                                    _c(
                                      "label",
                                      {
                                        staticClass:
                                          "kt-checkbox kt-checkbox--solid kt-checkbox--single mt-3",
                                        class: {
                                          "kt-checkbox--primary kt-checkbox--bold":
                                            row.rowSelected,
                                        },
                                        staticStyle: {
                                          "pointer-events": "none",
                                        },
                                      },
                                      [
                                        _c("input", {
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: row.rowSelected,
                                          },
                                        }),
                                        _c("span"),
                                      ]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "head(selected)",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      { staticStyle: { position: "relative" } },
                                      [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "kt-checkbox kt-checkbox--solid kt-checkbox--single",
                                            staticStyle: {
                                              top: "-19px",
                                              position: "absolute",
                                            },
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.selectAll,
                                                  expression: "selectAll",
                                                },
                                              ],
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.selectAll
                                                )
                                                  ? _vm._i(
                                                      _vm.selectAll,
                                                      null
                                                    ) > -1
                                                  : _vm.selectAll,
                                              },
                                              on: {
                                                change: function ($event) {
                                                  var $$a = _vm.selectAll,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        (_vm.selectAll =
                                                          $$a.concat([$$v]))
                                                    } else {
                                                      $$i > -1 &&
                                                        (_vm.selectAll = $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          ))
                                                    }
                                                  } else {
                                                    _vm.selectAll = $$c
                                                  }
                                                },
                                              },
                                            }),
                                            _c("span"),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "cell(googleCourseState)",
                                fn: function (row) {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "kt-badge kt-badge--inline kt-badge--sm kt-font-bold",
                                        class: _vm.getClassNameForState(
                                          row.item.googleCourseState
                                        ),
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(row.item.googleCourseState) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "cell(extOwnerId)",
                                fn: function (row) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          width: "120px",
                                          "white-space": "nowrap",
                                          overflow: "hidden",
                                          "text-overflow": "ellipsis",
                                        },
                                        attrs: { title: row.item.extOwnerId },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(row.item.extOwnerId) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    row.item.courseSections
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "text-muted",
                                            staticStyle: {
                                              width: "120px",
                                              "white-space": "nowrap",
                                              overflow: "hidden",
                                              "text-overflow": "ellipsis",
                                            },
                                            attrs: {
                                              title: row.item.courseSections,
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "flaticon2-correct kt-font-success",
                                            }),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  row.item.courseSections
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c(
                            "div",
                            {
                              staticClass:
                                "kt-datatable__pager kt-datatable--paging-loaded",
                            },
                            [
                              _vm.perPage < _vm.totalRows
                                ? _c("b-pagination", {
                                    staticClass: "kt-datatable__pager-nav",
                                    attrs: {
                                      "total-rows": _vm.totalRows,
                                      "per-page": _vm.perPage,
                                      size: "md",
                                    },
                                    model: {
                                      value: _vm.currentPage,
                                      callback: function ($$v) {
                                        _vm.currentPage = $$v
                                      },
                                      expression: "currentPage",
                                    },
                                  })
                                : _c("div", {
                                    staticClass: "kt-datatable__pager-nav",
                                  }),
                              _c(
                                "div",
                                { staticClass: "kt-datatable__pager-info" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "kt-datatable__pager-detail mr-3",
                                    },
                                    [_vm._v(" Page size ")]
                                  ),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mb-0",
                                      attrs: {
                                        "label-align-sm": "right",
                                        "label-size": "md",
                                      },
                                    },
                                    [
                                      _c("b-form-select", {
                                        attrs: {
                                          id: "per-page-select",
                                          options: _vm.pageOptions,
                                          size: "md",
                                        },
                                        model: {
                                          value: _vm.perPage,
                                          callback: function ($$v) {
                                            _vm.perPage = $$v
                                          },
                                          expression: "perPage",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }