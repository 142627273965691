var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "kt-portlet kt-portlet--head-noborder" }, [
    _c("div", { staticClass: "kt-portlet__head" }, [
      _vm._m(0),
      _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
        _c("small", [
          _vm._v(_vm._s(_vm.user.lastName) + ", " + _vm._s(_vm.user.firstName)),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "kt-portlet__body kt-portlet__body--fit-top" }, [
      _c("div", { staticClass: "kt-section kt-section--space-md" }, [
        _vm._v(" Your "),
        _vm.unlinkedGoogleEmail
          ? _c("code", [_vm._v(_vm._s(_vm.unlinkedGoogleEmail))])
          : _vm._e(),
        _vm._v(" account has not been authenticated against Google. "),
        _c("br"),
        _vm._v(" "),
        _c("br"),
        _vm._v(
          " Some features will be unavailable. Please link your SyncGrades account with Google Classroom. "
        ),
      ]),
      _c("div", { staticClass: "kt-section kt-section--last" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-brand btn-sm btn-bold",
            attrs: {
              href: _vm.$_userMixins_isSuperAdmin
                ? `javascript:alert('This cannot be performed while impersonating')`
                : _vm.redirectUri,
            },
          },
          [_vm._v(" Link Google Account ")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-portlet__head-label" }, [
      _c("h3", { staticClass: "kt-portlet__head-title kt-font-danger" }, [
        _vm._v(" Google Account Link "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }