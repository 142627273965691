<template>
<div>
    <GeneralSubHeader v-if="!isSetupWizard">
        <template #title>
            Google Course Manager
        </template>
        <template #left-buttons>
            <!-- what -->
        </template>
        <template #buttons>
            <div
                v-if="accountLinked"
                class="kt-subheader__wrapper"
            >
                <button
                    v-if="!isBulkReady"
                    :disabled="saving"
                    class="btn btn-primary"
                    @click.stop.prevent="showBulkModal"
                >
                    <i class="socicon-google mt-1 mr-1" />
                    Apply {{ selectedGoogleCourses.length }} Bulk Actions
                </button>

                <button
                    v-else
                    class="btn btn-label-primary"
                    :disabled="true"
                >
                    Apply Bulk Action
                </button>

                <button
                    class="btn btn-primary ml-3"
                    :disabled="saving || refreshing"
                    :class="{ 'kt-spinner kt-spinner--sm kt-spinner--light': refreshing }"
                    @click.stop.prevent="refreshCourses()"
                >
                    <i
                        v-if="!refreshing"
                        class="la la-refresh"
                    />

                    {{ refreshing ? 'Please wait...' : 'Refresh Courses' }}
                </button>
            </div>
        </template>
    </GeneralSubHeader>
    <b-modal
        ref="bulk-apply-modal"
        size="xl"
        centered
    >
        <template #modal-header="{ close }">
            <h5 class="modal-title">
                <i
                    :class="activeTab.icon"
                    class="mr-2 kt-font-primary"
                />

                {{ activeTab.title }}
            </h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="close()"
            />
        </template>
        <template #default>
            <div class="kt-portlet__body pt-3 pb-4 pl-4 pr-4">
                <ul
                    class="nav nav-pills"
                    role="tablist"
                >
                    <li
                        v-for="option in bulk.options"
                        :key="`key_${option.name}`"
                        class="nav-link"
                        @click.stop.prevent="selectBulkOption(option.name)"
                    >
                        <a
                            class="nav-link pt-3 pb-3 pr-4"
                            :class="{ active: option.active }"
                            href="#"
                        >
                            <i
                                :class="option.icon"
                                class="mr-2"
                            />
                            {{ option.name }}
                        </a>
                    </li>
                </ul>
                <div
                    v-if="selectedGoogleCourses.length > 0 && user.googleEmail"
                    class="tab-content p-4"
                >
                    <template v-if="activeTab.name == 'Change Owner'">
                        <div class="kt-section kt-section--first">
                            <div class="kt-section__body">
                                <div class="form-group row validated">
                                    <label class="col-lg-3 col-form-label">New Owner:</label>
                                    <div class="col-lg-6">
                                        <input
                                            v-model="bulk.newOwner"
                                            :class="!bulk.newOwner || isValidOwnerDomain ? '' : 'is-invalid'"
                                            :placeholder="`e.g. barack.obama@${googleDomain}`"
                                            type="email"
                                            class="form-control"
                                        >
                                        <span
                                            v-if="bulk.newOwner == ''"
                                            class="form-text text-muted"
                                        > Please enter the
                                            email address of the new owner </span>
                                        <span
                                            v-else-if="!isValidOwnerDomain"
                                            class="form-text text-muted invalid-warn-feedback"
                                        >
                                            Please enter a valid email address ending in
                                            <code>@{{ googleDomain }}</code>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-if="activeTab.name == 'Add Teacher'">
                        <div class="kt-section kt-section--first">
                            <div class="kt-section__body">
                                <div class="form-group row">
                                    <label class="col-lg-3 col-form-label">Email Address:</label>
                                    <div class="col-lg-6">
                                        <input
                                            v-model="bulk.teacherToAdd"
                                            :class="!bulk.teacherToAdd || isValidTeacherToAdd ? '' : 'is-invalid'"
                                            :placeholder="`e.g. barack.obama@${googleDomain}`"
                                            type="email"
                                            class="form-control"
                                        >
                                        <span
                                            v-if="bulk.teacherToAdd == ''"
                                            class="form-text text-muted"
                                        > Please enter
                                            the email address of the the teacher to add </span>
                                        <span
                                            v-else-if="!isValidTeacherToAdd"
                                            class="form-text text-muted invalid-warn-feedback"
                                        >
                                            Please enter a valid email address ending in
                                            <code>@{{ googleDomain }}</code>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-if="activeTab.name == 'Remove Teacher'">
                        <div class="kt-section kt-section--first">
                            <div class="kt-section__body">
                                <div class="form-group row">
                                    <label class="col-lg-3 col-form-label">Email Address:</label>
                                    <div class="col-lg-6">
                                        <input
                                            v-model="bulk.teacherToRemove"
                                            :class="!bulk.teacherToRemove || isValidTeacherToRemove ? '' : 'is-invalid'"
                                            :placeholder="`e.g. barack.obama@${googleDomain}`"
                                            type="email"
                                            class="form-control"
                                        >
                                        <span
                                            v-if="bulk.teacherToRemove == ''"
                                            class="form-text text-muted"
                                        > Please
                                            enter the email address of the the teacher to remove </span>
                                        <span
                                            v-else-if="!isValidTeacherToRemove"
                                            class="form-text text-muted invalid-warn-feedback"
                                        >
                                            Please enter a valid email address ending in
                                            <code>@{{ googleDomain }}</code>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-if="activeTab.name == 'Change State'">
                        <div class="kt-section kt-section--first">
                            <div class="kt-section__body">
                                <div class="form-group row pl-5 pt-2">
                                    <div class="col-lg-4">
                                        <label class="kt-option kt-option--plain">
                                            <span class="kt-option__control">
                                                <span class="kt-radio kt-radio--success">
                                                    <input
                                                        v-model="bulk.newState"
                                                        type="radio"
                                                        name="googleCourseState"
                                                        value="ACTIVE"
                                                    >
                                                    <span />
                                                </span>
                                            </span>
                                            <span class="kt-option__label">
                                                <span class="kt-option__head">
                                                    <span class="kt-option__title kt-font-success"> Activate </span>
                                                </span>
                                                <span class="kt-option__body"> Mark the course as active. Active courses
                                                    are available to teachers and students in google classroom </span>
                                            </span>
                                        </label>
                                    </div>
                                    <div class="col-lg-4">
                                        <label class="kt-option kt-option--plain">
                                            <span class="kt-option__control">
                                                <span class="kt-radio kt-radio--warning">
                                                    <input
                                                        v-model="bulk.newState"
                                                        type="radio"
                                                        name="googleCourseState"
                                                        value="ARCHIVED"
                                                    >
                                                    <span />
                                                </span>
                                            </span>
                                            <span class="kt-option__label">
                                                <span class="kt-option__head">
                                                    <span class="kt-option__title kt-font-warning"> Archive </span>
                                                </span>
                                                <span class="kt-option__body"> Mark the course as archived. You cannot
                                                    modify archived courses except to mark as active. </span>
                                            </span>
                                        </label>
                                    </div>
                                    <div class="col-lg-4">
                                        <label class="kt-option kt-option--plain">
                                            <span class="kt-option__control">
                                                <span class="kt-radio kt-radio--danger">
                                                    <input
                                                        v-model="bulk.newState"
                                                        type="radio"
                                                        name="googleCourseState"
                                                        value="DELETED"
                                                    >
                                                    <span />
                                                </span>
                                            </span>
                                            <span class="kt-option__label">
                                                <span class="kt-option__head">
                                                    <span class="kt-option__title kt-font-danger"> Delete </span>
                                                </span>
                                                <span class="kt-option__body"> Once a course is deleted, it is
                                                    permanently removed from google classroom. Grade data will remain in
                                                    SyncGrades after deletion. </span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <div
                                    v-if="bulk.newState == 'DELETED'"
                                    class="form-group row"
                                >
                                    <label class="col-lg-3 col-form-label">Confirm Delete:</label>
                                    <div class="col-lg-6">
                                        <input
                                            v-model="bulk.deleteConfirm"
                                            type="text"
                                            class="form-control"
                                        >

                                        <span class="form-text text-muted kt-font-danger">
                                            Please enter the text <code>{{ bulk.deleteConfirmText }}</code>
                                            to acknowledge that this delete is permanant and to proceed
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </template>
        <template #modal-footer="{ close }">
            <button
                type="button"
                class="btn btn-secondary mr-3"
                @click="close()"
            >
                Cancel
            </button>

            <a
                href="#"
                class="btn btn-primary btn-bold pull-right"
                :class="{ 'kt-spinner kt-spinner--sm kt-spinner--light': saving }"
                :disabled="saving"
                @click.stop.prevent="applyBulk()"
            >
                <i
                    v-if="!saving"
                    class="fa fa-save"
                />
                {{ saving ? 'Saving...' : 'Apply Changes' }}
            </a>
        </template>
    </b-modal>
    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid pl-0 pb-5">
        <SetupWizardStepStatus
            v-if="isSetupWizard && step"
            :status="step.status"
            :message="step.message"
        />

        <b-overlay
            no-center
            opacity="0.8"
            variant="light"
            :show="saving"
        >
            <GoogleAccountWarning v-if="!accountLinked" />

            <div class="kt-portlet">
                <div class="kt-portlet__body">
                    <div class="kt-form kt-form--label-right kt-margin-t-20 kt-margin-b-10">
                        <div class="row align-items-center">
                            <div class="col-xl-8 order-2 order-xl-1">
                                <div class="row align-items-center">
                                    <div class="col-md-4 kt-margin-b-20-tablet-and-mobile">
                                        <div class="kt-input-icon kt-input-icon--left">
                                            <b-form-input
                                                v-model="filter"
                                                debounce="500"
                                                class="form-control"
                                                placeholder="Search..."
                                                type="text"
                                            />
                                            <span class="kt-input-icon__icon kt-input-icon__icon--left">
                                                <span><i class="la la-search" /></span>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-4 kt-margin-b-20-tablet-and-mobile">
                                        <div class="kt-form__group kt-form__group--inline">
                                            <div class="kt-form__label">
                                                <label />
                                            </div>
                                            <div class="kt-form__control dropdown bootstrap-select">
                                                <b-dropdown
                                                    no-caret
                                                    :text="courseState || 'Any Status'"
                                                    class="m-2 btn-light w-100"
                                                >
                                                    <b-dropdown-item-button
                                                        class="dropdown-item pl-4 pt-3 pb-3 pr-3"
                                                        @click.stop.prevent="courseState = null"
                                                    >
                                                        Any Status
                                                    </b-dropdown-item-button>
                                                    <b-dropdown-item-button
                                                        class="dropdown-item pl-4 pt-3 pb-3 pr-3"
                                                        @click.stop.prevent="courseState = 'ACTIVE'"
                                                    >
                                                        Active Courses
                                                    </b-dropdown-item-button>
                                                    <b-dropdown-item-button
                                                        class="dropdown-item pl-4 pt-3 pb-3 pr-3"
                                                        @click.stop.prevent="courseState = 'ARCHIVED'"
                                                    >
                                                        Archived Courses
                                                    </b-dropdown-item-button>
                                                </b-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 kt-margin-b-20-tablet-and-mobile">
                                        <div class="kt-form__group kt-form__group--inline">
                                            <div class="kt-form__label">
                                                <label />
                                            </div>
                                            <div class="kt-form__control dropdown bootstrap-select">
                                                <b-dropdown
                                                    no-caret
                                                    :text="linkState || 'All Courses'"
                                                    class="m-2 btn-light w-100"
                                                >
                                                    <b-dropdown-item-button
                                                        class="dropdown-item pl-4 pt-3 pb-3 pr-3"
                                                        @click.stop.prevent="linkState = null"
                                                    >
                                                        All Courses
                                                    </b-dropdown-item-button>
                                                    <b-dropdown-item-button
                                                        class="dropdown-item pl-4 pt-3 pb-3 pr-3"
                                                        @click.stop.prevent="linkState = 'LINKED'"
                                                    >
                                                        Linked this term
                                                    </b-dropdown-item-button>
                                                    <b-dropdown-item-button
                                                        class="dropdown-item pl-4 pt-3 pb-3 pr-3"
                                                        @click.stop.prevent="linkState = 'NOT LINKED'"
                                                    >
                                                        Not Linked this term
                                                    </b-dropdown-item-button>
                                                </b-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="kt-portlet__body kt-portlet__body--fit">
                        <!--begin: Datatable -->
                        <div class="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
                            <b-table
                                id="data-set"
                                ref="dataSet"
                                class="mt-5"
                                :items="courses"
                                :fields="fields"
                                :current-page="currentPage"
                                :per-page="perPage"
                                :filter="filter"
                                stacked="sm"
                                selectable
                                fixed
                                selected-variant=""
                                show-empty
                                hover
                                responsive
                                small
                                select-mode="multi"
                                @row-selected="onRowSelected"
                                @filtered="onFiltered"
                            >
                                <template #table-colgroup="scope">
                                    <col
                                        v-for="field in scope.fields"
                                        :key="field.key"
                                        :style="field.style || ''"
                                    >
                                </template>

                                <template #cell(googleCourseName)="row">
                                    <div class="course-title">
                                        <a
                                            target="_blank"
                                            :title="`Open in Google: ${row.item.googleCourseName}`"
                                            class="kt-link"
                                            :href="row.item.alternateLink"
                                        >
                                            {{ row.item.googleCourseName }}
                                        </a>
                                    </div>
                                    <div
                                        v-if="row.item.googleCourseSection"
                                        class="course-title text-muted"
                                        :title="row.item.googleCourseSection"
                                    >
                                        {{ row.item.googleCourseSection }}
                                    </div>
                                </template>

                                <template #cell(selected)="row">
                                    <label
                                        class="kt-checkbox kt-checkbox--solid kt-checkbox--single mt-3"
                                        style="pointer-events: none"
                                        :class="{
                                            'kt-checkbox--primary kt-checkbox--bold': row.rowSelected,
                                        }"
                                    >
                                        <input
                                            type="checkbox"
                                            :checked="row.rowSelected"
                                        >
                                        <span />
                                    </label>
                                </template>

                                <!-- A custom formatted header cell for field 'selected' -->
                                <template #head(selected)>
                                    <div style="position: relative">
                                        <label
                                            class="kt-checkbox kt-checkbox--solid kt-checkbox--single"
                                            style="top: -19px; position: absolute"
                                        >
                                            <input
                                                v-model="selectAll"
                                                type="checkbox"
                                            >
                                            <span />
                                        </label>
                                    </div>
                                </template>

                                <template #cell(googleCourseState)="row">
                                    <span
                                        class="kt-badge kt-badge--inline kt-badge--sm kt-font-bold"
                                        :class="getClassNameForState(row.item.googleCourseState)"
                                    >
                                        {{ row.item.googleCourseState }}
                                    </span>
                                </template>
                                <template #cell(extOwnerId)="row">
                                    <div
                                        :title="row.item.extOwnerId"
                                        style="width: 120px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
                                    >
                                        {{ row.item.extOwnerId }}
                                    </div>
                                    <div
                                        v-if="row.item.courseSections"
                                        class="text-muted"
                                        style="width: 120px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
                                        :title="row.item.courseSections"
                                    >
                                        <i class="flaticon2-correct kt-font-success" />
                                        {{ row.item.courseSections }}
                                    </div>
                                </template>
                            </b-table>
                            <div class="kt-datatable__pager kt-datatable--paging-loaded">
                                <b-pagination
                                    v-if="perPage < totalRows"
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    size="md"
                                    class="kt-datatable__pager-nav"
                                />
                                <div
                                    v-else
                                    class="kt-datatable__pager-nav"
                                />

                                <div class="kt-datatable__pager-info">
                                    <span class="kt-datatable__pager-detail mr-3"> Page size </span>

                                    <b-form-group
                                        label-align-sm="right"
                                        label-size="md"
                                        class="mb-0"
                                    >
                                        <b-form-select
                                            id="per-page-select"
                                            v-model="perPage"
                                            :options="pageOptions"
                                            size="md"
                                        />
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                        <!--end: Datatable -->
                    </div>
                </div>
            </div>
            <template #overlay>
                <div class="text-center mt-5">
                    <template v-if="!bulk.canCloseOverlay">
                        <b-icon
                            icon="stopwatch"
                            font-scale="3"
                            animation="cylon"
                        />

                        <h6 class="mt-4">
                            These operations can take some time. Please keep this window open until completed.
                        </h6>
                    </template>
                    <template v-else>
                        <h4 class="mt-4 ml-4 text-left kt-font-primary">
                            <b-icon
                                icon="check"
                                class="kt-font-primary mr-3"
                                font-scale="1.2"
                            />
                            Bulk action has completed.

                            <button
                                class="btn btn-sm btn-primary pull-right mr-3"
                                @click.stop.prevent="closeOverlay"
                            >
                                <i class="la la-close" />
                                Close
                            </button>
                        </h4>
                    </template>

                    <div class="p-4 mt-2">
                        <div class="progress progress--lg">
                            <div
                                class="progress-bar kt-bg-primary"
                                role="progressbar"
                                :style="{ width: percentComplete + '%' }"
                            >
                                {{ percentComplete }}%
                            </div>
                        </div>
                    </div>
                    <div class="logs p-4 text-left">
                        <p
                            v-for="(item, idx) in logs"
                            :key="`item_${idx}`"
                        >
                            <span
                                v-if="item.status == 'info'"
                                class="kt-badge kt-badge--info kt-badge--inline mr-3"
                            >INFO</span>
                            <span
                                v-if="item.status == 'success'"
                                class="kt-badge kt-badge--success kt-badge--inline mr-3"
                            >SUCCESS</span>
                            <span
                                v-if="item.status == 'danger'"
                                class="kt-badge kt-badge--danger kt-badge--inline mr-3"
                            >ERROR</span>
                            {{ item.message }}
                        </p>
                    </div>
                </div>
            </template>
        </b-overlay>
    </div>
</div>
</template>

<script lang="ts">
import { mapState } from 'vuex';
import async from 'async';
import Types from '../../store/Types';
import GeneralSubHeader from '../../components/GeneralSubHeader.vue';
import SetupWizardStepStatus from '../../components/SetupWizardStepStatus.vue';
import GoogleAccountWarning from '../../components/GoogleAccountWarning.vue';
import * as network from '../../network';

export default {
    name: 'GoogleCourseManager',
    components: {
        GeneralSubHeader,
        SetupWizardStepStatus,
        GoogleAccountWarning,
    },
    data() {
        return {
            currentPage: 1,
            filter: null,
            courseState: null,
            linkState: null,
            logs: [],
            pageOptions: [100, 250, 500, 1000],
            percentComplete: 0,
            perPage: 100,
            saving: false,
            refreshing: false,
            selectedGoogleCourses: [],
            totalRows: 1,
            bulk: {
                canCloseOverlay: false,
                teacherToAdd: null,
                teacherToRemove: null,
                newOwner: null,
                deleteConfirm: null,
                deleteConfirmText: 'DELETE FOREVER',
                newState: 'ACTIVE',
                options: [
                    {
                        name: 'Change State',
                        title: 'Change Course State',
                        active: false,
                        icon: 'fa fa-cog',
                    },
                    {
                        name: 'Change Owner',
                        title: 'Modify Course Owner',
                        icon: 'fa fa-user-tie',
                        active: true,
                    },
                    {
                        name: 'Add Teacher',
                        title: 'Add Teacher to google course',
                        active: false,
                        icon: 'fa fa-user-plus',
                    },
                    {
                        name: 'Remove Teacher',
                        title: 'Remove Teacher from google course',
                        active: false,
                        icon: 'fa fa-user-minus',
                    },
                ],
            },
            fields: [
                {
                    key: 'selected',
                    label: '',
                    style: 'width: 46px',
                },
                {
                    key: 'extGoogleCourseId',
                    label: 'CourseId',
                    sortable: true,
                    style: 'width: 120px;',
                },
                {
                    key: 'googleCourseState',
                    sortable: true,
                    label: 'State',
                    style: 'width: 100px;',
                },
                {
                    key: 'googleCourseName',
                    sortable: true,
                    label: 'Name',
                    style: 'width: 260px',
                },
                {
                    key: 'extOwnerId',
                    sortable: true,
                    label: 'Owner',
                    style: 'width: 120px;',
                },
            ],
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            deviceType: (state) => state.deviceType,
            googleCourses: (state) => state.database.googleCourses,
            googleCourseCourseSections: (state) => state.database.googleCourseCourseSections,
            courseSections: (state) => state.database.courseSections,
            googleTeachers: (state) => state.database.googleTeachers,
        }),
        isSetupWizard() {
            return this.$route.name == 'SetupWizard';
        },
        steps() {
            return this.$store.state.wizard.steps;
        },
        step() {
            return this.steps.find((r) => r.routeName == 'SetupGoogleArchive');
        },
        courses() {
            const { courseState, linkState } = this;
            return this.googleCourses
                .filter((g) => {
                    if (!g.visibleToUser) return false;
                    if (!courseState) return true;
                    return g.googleCourseState == courseState;
                })
                .filter((g) => {
                    if (!linkState) return true;
                    if (linkState == 'LINKED') return Boolean(g.courseSections);
                    if (linkState == 'NOT LINKED') return !g.courseSections;
                    return true;
                });
        },
        accountLinked() {
            return this.user.googleAccountId;
        },
        selectAll: {
            get() {
                const v = this;
                return v.selectedGoogleCourses.length > 0;
            },
            set() {
                if (this.selectedGoogleCourses.length == 0) {
                    this.$refs.dataSet.selectAllRows();
                } else {
                    this.selectedGoogleCourses = [];
                    this.$refs.dataSet.clearSelected();
                }
            },
        },
        activeTab() {
            return this.bulk.options.find((o) => o.active);
        },
        items() {
            const {
                googleTeachers, googleCourses, courseSections, googleCourseCourseSections,
            } = this;
            return googleCourses
                .filter((c) => c.visibleToUser)
                .map((g) => {
                    const googleCourse = g;
                    const { googleCourseId, extOwnerId } = googleCourse;
                    const courseSectionIds = googleCourseCourseSections.filter((c) => c.googleCourseId == googleCourseId).map((c) => c.courseSectionId);

                    googleCourse.courseSections = courseSections
                        .filter((c) => courseSectionIds.includes(c.courseSectionId))
                        .map((c) => `${c.extCourseSectionId}`)
                        .join(', ');

                    const owner = googleTeachers.find((t) => t.googleUserId == extOwnerId);
                    googleCourse.extOwnerId = owner && owner.emailAddress ? owner.emailAddress : googleCourse.extOwnerId;
                    return googleCourse;
                });
        },
        canDelete() {
            return this.bulk.deleteConfirm == this.bulk.deleteConfirmText;
        },
        isBulkReady() {
            return Boolean(this.selectedGoogleCourses.length == 0);
        },
        googleDomain() {
            if (!this.user.googleEmail) return null;
            return this.user.googleEmail.split('@')[1].toLowerCase();
        },
        isValidTeacherToRemove() {
            const { bulk, googleDomain } = this;
            if (!bulk.teacherToRemove) return false;
            const googleEmail = bulk.teacherToRemove.toLowerCase();
            const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!googleEmail.endsWith(googleDomain)) return false;
            return re.test(googleEmail);
        },
        isValidTeacherToAdd() {
            const { bulk, googleDomain } = this;
            if (!bulk.teacherToAdd) return false;
            const googleEmail = bulk.teacherToAdd.toLowerCase();
            const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!googleEmail.endsWith(googleDomain)) return false;
            return re.test(googleEmail);
        },
        isValidOwnerDomain() {
            const { bulk, googleDomain } = this;
            if (!bulk.newOwner) return false;
            const googleEmail = bulk.newOwner.toLowerCase();
            const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!googleEmail.endsWith(googleDomain)) return false;
            return re.test(googleEmail);
        },
    },
    mounted() {
        const v = this;
        // this.$store.dispatch(Types.actions.CALCULATE_SETUP_STEPS);

        // Set the initial number of items
        this.totalRows = this.items.length;
        this.bulk.teacherToAdd = this.user.googleEmail || null;
        this.bulk.teacherToRemove = this.user.googleEmail || null;
        this.bulk.newOwner = this.user.googleEmail || null;

        this.$watch(
            (vm) => [vm.totalRows, vm.linkState, vm.courseState, vm.filter],
            () => {
                v.totalRows = v.courses.length;
                v.currentPage = 1;
            },
        );
    },
    methods: {
        refreshCourses() {
            const v = this;
            if (this.refreshing) return;
            this.refreshing = true;
            const { schoolTermId, schoolId } = this.user.school;
            network.google.refreshGoogleCourseAccess({ url: { schoolTermId, schoolId } }, (err) => {
                if (err) v.showError('Error Refreshing');
                this.refreshing = false;
                v.$store.dispatch(Types.actions.REFRESH_GOOGLE_COURSES);
            });
        },
        getClassNameForState(googleCourseState) {
            if (googleCourseState == 'ACTIVE') return 'kt-badge--primary';
            if (googleCourseState == 'ARCHIVED') return 'kt-badge--warning';
            if (googleCourseState == 'DELETED') return 'kt-badge--danger';
            if (googleCourseState == 'DECLINED') return 'kt-badge--warning';
            return 'kt-badge--dark';
        },
        closeOverlay() {
            this.saving = false;
            this.logs = [];
        },
        percentage(num, den) {
            if (num <= 0 || den <= 0) return 0;
            const percentage = (num / den) * 100;
            const roundToDecimals = 1;
            return Number(`${Math.round(`${percentage}e${roundToDecimals}`)}e-${roundToDecimals}`);
        },
        checkEmailValidity(email) {
            const { googleDomain } = this;
            const { googleEmail } = (email || '').toLowerCase();
            const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!re.test(googleEmail)) return false;
            return googleEmail.endsWith(googleDomain);
        },
        applyBulk() {
            if (this.saving) return;

            const { showError, showNotification, hideBulkModal } = this;

            // client side validation
            const googleCourseState = this.bulk.newState;

            switch (this.activeTab.name) {
            case 'Change Owner':
                if (!this.isValidOwnerDomain) {
                    return showError('Please enter a valid email address');
                }
                break;
            case 'Change State':
                if (!this.canDelete && googleCourseState == 'DELETED') {
                    this.saving = false;
                    return showError(`Please enter the text ${this.bulk.deleteConfirmText} to confirm deletion`);
                }
                break;
            case 'Remove Teacher':
                if (!this.isValidTeacherToRemove) {
                    return showError('Please enter a valid email address');
                }
                break;
            case 'Add Teacher':
                if (!this.isValidTeacherToAdd) {
                    return showError('Please enter a valid email address');
                }
                break;
            default:
                break;
            }

            window.scrollTo(0, 0);

            this.saving = true;
            this.bulk.canCloseOverlay = false;

            setTimeout(hideBulkModal, 300);

            const v = this;
            const { log, googleCourses } = this;
            const { selectedGoogleCourses, activeTab, bulk } = this;
            const { schoolId, schoolTermId } = this.user.school;

            async.eachOfSeries(selectedGoogleCourses, (googleCourseId, idx, nextCourse) => {
                const googleCourse = googleCourses.find((c) => c.googleCourseId == googleCourseId);
                const { extGoogleCourseId, googleCourseName } = googleCourse;
                const counter = idx + 1;
                const { commit } = v.$store;
                let googleEmail = null;
                v.percentComplete = v.percentage(counter, selectedGoogleCourses.length);

                switch (activeTab.name) {
                case 'Change State':
                    log(`${counter}. Applying \`${bulk.newState}\` state to ${extGoogleCourseId} ${googleCourseName}`, 'info');
                    network.google.modifyGoogleCourse({
                        url: { schoolTermId, schoolId, googleCourseId },
                        body: { googleCourseState },
                    }, (err) => {
                        if (err) {
                            window.console.log(err);
                            log(`${counter}. Error setting \`${bulk.newState}\` state to ${extGoogleCourseId}: ${JSON.stringify(err)}`, 'danger');
                        } else {
                            log(`${counter}. Finished Course Id ${extGoogleCourseId}`, 'success');
                            commit(Types.mutations.EDIT_GOOGLE_COURSE_STATE, { googleCourseId, googleCourseState });
                        }
                        nextCourse();
                    });
                    break;
                case 'Change Owner':
                    googleEmail = bulk.newOwner;

                    if (!googleEmail) return nextCourse(new Error('Missing email for owner'));

                    log(`${counter}. Changing owner to ${googleEmail} for ${extGoogleCourseId} ${googleCourseName}`, 'info');

                    network.google.changeCourseOwner(
                        {
                            url: {
                                schoolId,
                                schoolTermId,
                                googleCourseId,
                                googleEmail,
                            },
                        },
                        (err, res) => {
                            if (err) {
                                window.console.log(err);
                                log(`${counter}. Error Changing owner to \`${googleEmail}\` for ${extGoogleCourseId}: ${JSON.stringify(err)}`, 'danger');
                            } else {
                                log(`${counter}. Finished Course Id ${extGoogleCourseId}`, 'success');
                                commit(Types.mutations.EDIT_GOOGLE_COURSE_OWNER, { googleCourseId, extOwnerId: res.googleCourse.extOwnerId });
                            }
                            nextCourse();
                        },
                    );
                    break;
                case 'Add Teacher':
                    googleEmail = bulk.teacherToAdd;
                    if (!googleEmail) return nextCourse(new Error('Missing email for onwner'));

                    log(`${counter}. Adding teacher \`${googleEmail}\` for ${googleCourseName} ${extGoogleCourseId}`, 'info');

                    network.google.addTeacherToCourse(
                        {
                            url: {
                                schoolId,
                                schoolTermId,
                                googleCourseId,
                            },
                            body: {
                                emailAddress: googleEmail,
                            },
                        },
                        (err) => {
                            if (err) {
                                window.console.log(err);
                                log(`${counter}. Error adding teacher \`${googleEmail}\` for ${extGoogleCourseId}: ${JSON.stringify(err)}`, 'danger');
                            } else {
                                log(`${counter}. Finished Course Id ${extGoogleCourseId}`, 'success');
                            }
                            nextCourse();
                        },
                    );

                    break;
                case 'Remove Teacher':
                    googleEmail = bulk.teacherToRemove;
                    if (!googleEmail) return nextCourse(new Error('Missing email for owner'));

                    log(`${counter}. Removing teacher \`${googleEmail}\` for ${googleCourseName} ${extGoogleCourseId}`, 'info');
                    network.google.removeTeacherFromCourse(
                        {
                            url: {
                                schoolId,
                                schoolTermId,
                                googleCourseId,
                            },
                            body: {
                                emailAddress: googleEmail,
                            },
                        },
                        (err) => {
                            if (err) {
                                window.console.log(err);
                                log(`${counter}. Error removing teacher \`${googleEmail}\` for ${extGoogleCourseId}: ${JSON.stringify(err)}`, 'danger');
                            } else {
                                log(`${counter}. Finished Course Id ${extGoogleCourseId}`, 'success');
                            }
                            nextCourse();
                        },
                    );
                    break;
                default:
                    nextCourse(new Error('unknown action'));
                }
            }, (err) => {
                bulk.canCloseOverlay = true;
                v.selectedGoogleCourses = [];
                v.$refs.dataSet.clearSelected();

                if (err) return showError(err);
                showNotification('Completed');
            });
        },
        selectBulkOption(name) {
            this.bulk.options = this.bulk.options.map((opt) => {
                const option = opt;
                option.active = Boolean(opt.name == name);
                return option;
            });
        },
        showBulkModal() {
            this.$refs['bulk-apply-modal'].show();
        },
        hideBulkModal() {
            this.$refs['bulk-apply-modal'].hide();
        },
        onRowSelected(items) {
            this.selectedGoogleCourses = items.map((i) => i.googleCourseId);
        },
        rowClass(item, type) {
            if (!item || type !== 'row') return null;
            if (item.googleCourseState === 'ARCHIVED') return 'table-warning';
            if (item.googleCourseState === 'ACTIVE') return 'table-primary';
            return 'table-secondary';
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        log(message, status = 'info') {
            this.logs.push({ message, status });
        },
    },
};
</script>
<style>
table#data-set td {
    vertical-align: middle !important;
    padding-bottom: 10px;
    padding-top: 10px;
}
</style>
<style scoped>
@media (min-width: 1025px) {
    label.col-form-label {
        text-align: right !important;
    }
}

div.course-title {
    width: 260px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

body.modal-open .footer-bar {
    display: none;
}

.control-width {
    min-width: 180px;
    width: 50%;
}

div.logs {
    font-family: 'Courier New', monospace;
    width: 100%;
    overflow: scroll;
}

div.logs span.kt-badge {
    display: inline-block;
    text-align: center;
    width: 60px;
}

div.logs p {
    font-weight: 600;
}

div.blockOverlay {
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0);
    opacity: 0.05;
    cursor: wait;
    position: absolute;
}

div.blockElement {
    z-index: 1011;
    position: absolute;
    margin-left: 41%;
    top: 250px;
}
</style>
