<template>
<div class="kt-portlet kt-portlet--head-noborder">
    <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title kt-font-danger">
                Google Account Link
            </h3>
        </div>
        <div class="kt-portlet__head-toolbar">
            <small>{{ user.lastName }}, {{ user.firstName }}</small>
        </div>
    </div>
    <div class="kt-portlet__body kt-portlet__body--fit-top">
        <div class="kt-section kt-section--space-md">
            Your <code v-if="unlinkedGoogleEmail">{{ unlinkedGoogleEmail }}</code> account has not been authenticated against Google.
            <br>&nbsp;<br>
            Some features will be unavailable. Please link your SyncGrades account with Google Classroom.
        </div>
        <div class="kt-section kt-section--last">
            <a
                :href="$_userMixins_isSuperAdmin ? `javascript:alert('This cannot be performed while impersonating')` : redirectUri"
                class="btn btn-brand btn-sm btn-bold"
            >
                Link Google Account
            </a>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import userMixins from '../store/mixins/userMixins';

export default {
    name: 'GoogleAccountWarning',
    mixins: [userMixins],
    computed: {
        redirectUri() {
            const { pathname } = window.location;
            return `/auth/google/redirect?callbackUrl=${pathname}`;
        },
        user() {
            return this.$_userMixins_user;
        },
        unlinkedGoogleEmail() {
            if (this.$_userMixins_isSuperAdmin) {
                return this.$_userMixins_googleEmail;
            }
            return this.$_userMixins_unlinkedGoogleEmail;
        },
    },
};
</script>
